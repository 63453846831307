import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: '/avatar'
  },
  {
    path: '/avatar',
    name: 'Avatar',
    component: () => import('../views/Avatar.vue')
  },
  {
    path: '/avatar_canvas',
    name: 'AvatarCanvas',
    component: () => import('../views/AvatarCanvas.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
